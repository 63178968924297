<template>
    <div>
        <slot name='filter'></slot>


        <div class="table-responsive recentOrderTable">
            <b-table hover :items="collection.data" :fields="fields"
                :busy="loading">
                <template #table-busy>
                    <loader/>
                </template>
                <template v-for="slotName in Object.keys($scopedSlots)" v-slot:[slotName]="slotScope">
                    <slot :name="slotName" v-bind="slotScope"></slot>
                </template>
            </b-table>
        </div>
        <div class='row justify-content-md-center'>
            <b-pagination
                v-model="currentPage"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                ></b-pagination>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'collection', 'fields', 'loading'],
    data: function() {
        return {
            currentPage: 1
        };
    },
    computed: {
        meta: function() {
            var meta = this.collection.meta ? this.collection.meta : {};
            return meta;
        }
    }
}
</script>
